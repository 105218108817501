import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/layout';
import { graphql } from 'gatsby';
import SeasonYear from '../../components/SeasonYear';
import OnstageOgdenImage from '../../assets/images/publication-banners/onstage-ogden.jpg';

export const query = graphql`
  query OgdenQuery {
    allOnogCsv {
      nodes {
        CD
        FR
        Quantity
        RUNNING_DATES
        SHOW
        Image
      }
    }
  }
`;

export default function OnstageOgden({ data }) {
  const ogdenNodes = data.allOnogCsv.nodes;
  // console.log(balletNodes);

  const imageRef = '/season-images/onog/';
  // append the image reference with the path to the image via the static folder

  return (
    <Layout>
      <Helmet>
        <title>Onstage Ogden - Mills Publishing Inc.</title>
        <meta
          name="description"
          content="Advertising and event information for Onstage Ogden’s season playbills. Please contact Mills Publishing for availability and rates."
        />
      </Helmet>

      <div id="main" className="alt">
        <section id="one">
          <div className="inner">
            <header className="major">
              <h1>Onstage Ogden</h1>
            </header>
            <span className="image main">
              <img src={OnstageOgdenImage} alt="Onstage Ogden Covers" />
            </span>
            <p className="box">
              Onstage Ogden is a community arts nonprofit dedicated to promoting
              classical music and dance in the Greater Ogden area. We believe
              the arts have the power to inspire and uplift, and we are firmly
              committed to providing everyone in our community with greater
              access to the arts.
              <br />
              <br />
              Formerly known as Ogden Symphony Ballet Association (OSBA),
              Onstage Ogden has been presenting world-class classical music
              programs in Ogden for 70 years. Since then we have presented over
              800 concerts. Each year, our performances are attended by over
              20,000 people.
              <br />
              <br />
              With performances at the Val A. Browning Center, Egyptian Theater,
              and The Monarch. Upon entering the wonderful venues, each patron
              will receive a playbill outlining the evening performance. We
              invite you to take advantage of this opportunity to reach Weber
              County’s most affluent and loyal supporters of the arts with
              playbill advertising. This is an excellent way to reach this
              market in a relaxed environment where the playbills become the
              focal point before, during, and after the several intermissions of
              these events and are often taken home.
            </p>
            <ul className="actions">
              <li>
                <a
                  href="https://www.onstageogden.org/"
                  className="button special"
                >
                  <i className="icon fa-globe"> </i>Onstage Ogden Website
                </a>
              </li>
            </ul>
            <h2>
              Onstage Ogden <SeasonYear /> Season Season Information
            </h2>
            <p>
              <em>
                Dates are subject to change. Please contact us if you have any
                questions.
              </em>
            </p>
            <div className="grid-wrapper">
              {/* Calls the season json, accesses the array, and maps/creates a new array */}
              {ogdenNodes.map((node) => (
                <div className="col-4 box" key={node.id}>
                  <img
                    style={{ width: 100 + '%' }}
                    src={imageRef + node.Image}
                  />
                  <h3>{node.SHOW}</h3>
                  <strong>{node.RUNNING_DATES}</strong> <br />
                  <br />
                  <h5>Advertising Information</h5>
                  <section className="box">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            File Ready Deadline: <br />
                            {node.FR}
                          </td>
                        </tr>
                        {/* <tr>
                          <td>Advertising Exposure: {node.exposure}</td>
                        </tr> */}
                        <tr>
                          <td>Print Quantity: {node.Quantity}</td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}
